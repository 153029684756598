import * as React from 'react';
import { IOfferingViewModel } from '../domain/offering-view-model-factory';
import { DeviceType } from '../widget-viewer-main';

export interface CatalogOfferingContext {
  offeringViewModel: IOfferingViewModel;
  deviceType: DeviceType;
}

export interface CatalogOfferingInjectedProps {
  offeringViewModel?: IOfferingViewModel;
  deviceType?: DeviceType;
}

const context = React.createContext<CatalogOfferingContext>(null);

export const OfferingContextProvider = context.Provider;

export const OfferingContextConsumer = context.Consumer;

export const withOfferingContext = () => <TProps extends {}>(
  WrappedComponent: React.ComponentType<TProps & CatalogOfferingInjectedProps>,
): React.ComponentType<
  TProps & Partial<CatalogOfferingInjectedProps>
> => props => (
  <OfferingContextConsumer>
    {(offeringContext: CatalogOfferingContext) => {
      const passedProps: CatalogOfferingInjectedProps = {
        offeringViewModel: offeringContext.offeringViewModel,
        deviceType: offeringContext.deviceType,
      };
      return <WrappedComponent {...passedProps} {...props} />;
    }}
  </OfferingContextConsumer>
);
